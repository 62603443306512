.line_box {
  display: flex;
  margin-bottom: 10px;
}

.text_circle {
  flex: 1;
  text-align: center;
  position: relative;
  color: grey !important;
}

.text_circle:after {
  background-color: grey;
  bottom: 1.25em;
  content: "";
  display: block;
  height: 3px;
  position: absolute;
  right: 0;
  width: 50%;
  z-index: 0;
}

.stav_projektu .text_circle:after,
.stav_projektu .text_circle:before {
  background-color: grey;
}

.line_box h4,
.line_box p {
  font-size: 12px;
  margin-bottom: 0;
  padding: 0 5px;
}

.subline {
  position: absolute;
  right: -25px;
  bottom: -43%;
}

.subline:before {
  content: "";
  position: absolute;
  height: 15px;
  width: 15px;
  bottom: 14px;
  right: 15px;
  background-color: var(--text-on-blue);
  border-radius: 50%;
  top: -25px;
}

.subline h6 {
  margin-bottom: 0;
}

.timeline {
  margin: 40px 0;
}

.text_circle.done:after,
.text_circle.done + .text_circle:before,
.stav_projektu .text_circle.done:after,
.stav_projektu .text_circle.done + .text_circle:before {
  background-color: #1890ff;
}

.text_circle.sub:after {
  background-color: #1890ff;
}

.text_circle:not(:first-child):before {
  bottom: 1.25em;
  content: "";
  display: block;
  height: 3px;
  position: absolute;
  left: 0;
  width: 50%;
  z-index: 0;
  background-color: grey;
}

.stav_projektu .text_circle:not(:first-child):before {
  background-color: grey;
}

.text_circle:last-child:after {
  width: 0;
}

.circle {
  height: 100%;
}

.tvar {
  height: 28px;
  width: 28px;
  border: 0px solid #1890ff;
  display: flex;
  position: relative;
  border-radius: 100%;
  top: -35px;
  margin: 3px auto;
  background-color: #fff;
  z-index: 1;
}

.tvar span {
  margin: 25% auto;
  height: 14px;
  width: 14px;
  background-color: #1890ff;
  border-radius: 100%;
  color: #fff;
}

.tvar .done {
  margin: 25% auto;
  height: 14px;
  width: 14px;
  background-color: #1890ff;
  border-radius: 100%;
  color: #fff;
}

.tvar .pending {
  margin: 25% auto;
  height: 14px;
  width: 14px;
  background-color: grey;
  border-radius: 100%;
  color: #fff;
}

.tvar .rejected {
  margin: 25% auto;
  height: 14px;
  width: 14px;
  background-color: var(--text-on-orange);
  border-radius: 100%;
  color: #fff;
}

.stav_projektu .tvar {
  border: 0px solid grey;
}

.stav_projektu .done .tvar,
.stav_projektu .sub .tvar {
  border: 0px solid var(--text-on-blue);
}

.subline h6 {
  color: var(--text-on-blue);
}

.timeline .card-header:hover {
  background-color: #ececec;
  cursor: pointer;
}

/* iPhone X ----------------------------------- */
@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  .subline:before {
    top: -43px;
  }
}
@media only screen and (device-width: 812px) and (device-height: 375px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3) {
  .subline:before {
    top: -31px;
  }
}
/* iPad portrait ----------------------------------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .subline:before {
    top: -29px;
  }
}
/* mobile width till 767px ----------------------------------- */
@media (max-width: 767px) {
  .subline:before {
    top: -30px;
  }
}
/* Portrait iPad Pro */
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
  .subline:before {
    top: -23px;
  }
}
/* mobile width till 480px ----------------------------------- */
@media (max-width: 480px) {
  .subline:before {
    top: -43px;
  }
}
.rc-tab {
  overflow: hidden;
  border: 1px solid #ccc;
}

.rc-tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 17px;
}

.rc-tab button:hover {
  background-color: #ddd;
}

.rc-tab button.active {
  border-bottom: 2px solid black;
}

.tabcontent {
  display: none;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
}

.content-row {
  display: flex;
  margin-top: 10px;
}

.content-object {
  margin: 10px;
  flex: 1;
}

.content-key {
  margin-bottom: 3px;
}

.content-val {
  color: rgb(69, 69, 69);
  margin-right: 40%;
  border-radius: 7px;
  padding: 5px;
  background: rgb(228, 227, 227);
}